<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				stocksplit {{perf}}
				<DataTable :value="stocksplits" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
          dataKey="id" :paginator="true" :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :globalFilterFields="['meigara_cd', 'meigara_name', 'split_ratio', 'split_date', 'udate', 'reflected']"
					selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="meigara_cd" header="銘柄コード" :sortable="true"></Column>
					<Column field="meigara_name" header="銘柄名" :sortable="true"></Column>
					<Column field="split_ratio" header="分割比率" :sortable="true"></Column>
					<Column field="split_date" header="分割日" :sortable="true"></Column>
          <Column field="udate" header="レコード更新日" :sortable="true"></Column>
          <Column field="reflected" header="ステータス" :sortable="true"></Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import StocksplitService from '@/service/StocksplitService';

export default {
	data() {
		return {
			stocksplits: null,
			filters1: {},
			perf: null,
		}
	},
	stocksplitService: null,
	created() {
		this.stocksplitService = new StocksplitService();
		this.initFilters1();
	},
	mounted() {
		this.stocksplitService.getAll()
			.then(d => {
				this.stocksplits = d.data;
				this.perf = d.perf;
			}
		);		
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		}
	}
}
</script>

<style scoped>

</style>
